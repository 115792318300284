
















import {Component, Mixins, Watch} from 'vue-property-decorator';
import PostInterface from '@/types/PostInterface';
import PeopleInterface from '@/types/PeopleInterface';
import Feed from '@/components/Feed.vue';
import LoaderBottom from '@/components/LoaderBottom.vue';
import {vxm} from '@/store';
import FeedMixin from '@/mixins/FeedMixin';

@Component({
  components: {Feed, LoaderBottom},
})
export default class Popular extends Mixins(FeedMixin) {
  people = [] as PeopleInterface[];
  loading = true;
  feed = [] as PostInterface[];
  searchFlag = false;
  page = 1;
  limit = 10;
  totalItems = 0;
  mounted() {
    this.scroll();
  }
  created() {
    this.peopleSearch();
  }
  @Watch('$route.params.token')
  doSearch() {
    if (this.$route.name !== 'popular') {
      this.searchFlag = true;
    } else {
      this.peopleSearch();
    }
  }
  @Watch('$route.name')
  watchRoute() {
    if (this.$route.name === 'popular' && this.searchFlag) {
      this.peopleSearch();
      this.searchFlag = false;
    }
  }
  scroll() {
    window.onscroll = () => {
      const bottomOfWindow =
        document.documentElement.scrollTop + window.innerHeight >= document.documentElement.scrollHeight;
      if (bottomOfWindow && this.totalItems < (this.page as number) * (this.limit as number)) {
        const searchToken = this.$route.params.token;
        this.loading = false;
        vxm.search
          .doSearch({search: searchToken, sort: 'popular', page: this.page, limit: this.limit})
          .then((res: any) => {
            this.page += 1;
            const data = res.data.data;
            this.feed.push(...data.posts);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    };
  }
  peopleSearch() {
    const searchToken = this.$route.params.token.replace('#', '%23');
    this.page = 1;
    if (searchToken) {
      vxm.search
        .doSearch({search: searchToken, sort: 'popular', page: this.page, limit: this.limit})
        .then((res: any) => {
          const data = res.data.data;
          this.people = data.users && data.users.length ? data.users : [];
          this.feed = data.posts && data.posts.length ? data.posts : [];
          this.page += 1;
          this.totalItems = data.posts.length;
        })
        .finally(() => {
          this.loading = false;
        });
    }
  }
  getAvatar(index: number) {
    return this.people[index].avatar || require('@/assets/icons/avatar-default.svg');
  }
}
